<script setup>
import { useContentful } from '~~/composables/useContentful'

const props = defineProps({
  // if the asset has been included in the graphql call
  url: {
    type: String,
    default: null,
  },

  // if the asset has not been included in the graphql call (will send an additional request)
  assetID: {
    type: String,
    default: null,
  },
})

const fetchedURL = ref(null)
const assetURL = computed(() => props.url || fetchedURL.value)

const contentful = useContentful()
async function fetchAssetData() {
  const response = await contentful.asset(props.assetID)
  fetchedURL.value = `${response.fields.file.url}?w=1200` // don't retrieve full-sized images
}

onMounted(() => {
  if (props.assetID) {
    fetchAssetData()
  }
})
</script>

<template>
  <img class="rich-text-image" :src="assetURL" alt="" />
</template>

<style lang="scss">
.rich-text-image {
  display: block;
  max-width: 100%;
  margin: 32px 0;
}
</style>
